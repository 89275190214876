import "firebase/firestore"
import React from "react"
import Boundary from "../components/Boundary"
import Gutter from "../components/Gutter"
import {Heading} from "../components/Heading"
import Page from "../components/Page"
import Spacer from "../components/Spacer"
import Donate from "../widgets/Donate"
import LocationMap from "../widgets/LocationMap"

const ContactPage = () => {
  return (
    <Page>
      <Spacer isDesktop={false} />
      <Boundary>
        <Gutter isDesktop={false}>
          <Gutter leftPadding={false} rightPadding={false}>
            <Heading type="1">Come visit us!</Heading>
            <p>
              If you're coming from Glades Road, turn north onto 4th Avenue and
              you will see our beautiful dome on the left. If you're coming from
              Yamato Road, head south on 4th Avenue until you approach Glades
              Road, and you will see us on the right. See you soon!
            </p>
            <LocationMap />
            <br />
            <br />
            <Donate />
          </Gutter>
        </Gutter>
      </Boundary>
    </Page>
  )
}

export default ContactPage
